import Accent from "../../Components/Accent";
import {IoSearch} from 'react-icons/io5'
import { MdAdd } from 'react-icons/md'
import { Link } from "react-router-dom";
import Modal from "../../Components/PopUps/Modal";
import { useEffect, useState } from "react";
import NewEventForm from "../../Components/Events/NewEventForm";
import { getEventDocs } from "../../Requests/firestore";
import Loading from "../../Components/Loading";
import { setCampaign } from "../../Requests/sendEmail";

export default function AdminEvents() {

  const [newEventModal, setNewEventModal] = useState(false)

  const [events, setEvents] = useState([])
  const [eventList, setEventList] = useState([])
  
  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState('')

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    let newList = []
    events.forEach(event=>{
      if(event.name.toLowerCase().includes(search.toLowerCase())){
        newList = [...newList, event]
      }
    })
    setEventList([...newList])
  }

  const handleNewEventModal = () => {
    setNewEventModal(!newEventModal)
  }

  const displayDate = (date) => {
    let t = new Date(Date.UTC(1970, 0, 1));
    t.setUTCSeconds(date);
    return `${t.getDate()}-${t.getMonth()+1}-${t.getFullYear()}`
  }
  
  
  useEffect(()=>{
    const getInfo = async ()=>{
      setLoading(true)
      const res = await getEventDocs()
      if(res.status==='success'){
        setEvents([...res.data])
        setEventList([...res.data])
      }else{
        setEvents([])
        setEventList([])
      }
      setLoading(false)
    }
    
    getInfo()

  },[])


  return(
    <div className="">
      {
        loading?<>
        <Loading/>
        </>:<>
          <div className="flex sm:justify-between items-center flex-col sm:flex-row gap-4">
              <h1 className="text-5xl flex justify-start ml-0 mr-auto items-center">
              <Accent/>
                Eventos</h1>
            <form onSubmit={handleSearchSubmit} className="w-full max-w-[350px] mx-auto sm:m-0 flex gap-2">
              <div className="input-holder w-full">
                <input className="w-full" type="text" id="search" placeholder=" " value={search} onChange={(e)=>{setSearch(e.target.value)}}/>
                <label htmlFor="search">Buscar</label> 
              </div>
              <button> <IoSearch size={25}/> </button>
            </form>
          </div>
          {
            events.length === 0?<>
            <span className="mx-auto text-center">
              No se encontraron eventos
            </span>
            </>:
          <table className="mt-4 w-full table-auto">
            <thead>
              <tr>
                <th colSpan='100%' className="py-4"><button className="action-btn gap-1" onClick={handleNewEventModal}><MdAdd size={25}/> nuevo</button></th>
              </tr>
              <tr>
                <th>Nombre</th>
                <th>Fecha límite</th>
                <th>Fecha del Evento</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-white">
              {
                eventList.map((event, index)=>{return(
                  <tr key={index}>
                    <td><Link to={`/admin/event/${event.id}/exhibitors`} className="hover:underline">{event.name}</Link></td>
                    <td>{displayDate(event.voteLimit.seconds)}</td>
                    <td>{displayDate(event.date.seconds)}</td>
                  </tr>
                )})
              }
            </tbody>
          </table>
          }
          <Modal isActive={newEventModal} handle={handleNewEventModal} title='Nuevo evento'> 
            <NewEventForm handle={handleNewEventModal} setEvents={setEvents} events={events}/>
          </Modal>
        </>
      }
    </div>
  )
}