import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../Requests/auth";
import './LogOut.css'
export default function LogOut(){
  const navigate = useNavigate()

  const handleSignOut = async () => {
    await signOut()
    navigate('/')
  }

  return <div className="log-out-btn">
    <button onClick={handleSignOut}><MdLogout size={30}/></button>
    <span>Salir</span>
  </div>
}