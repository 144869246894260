import { useCallback, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Accent from "../../Components/Accent"
import Loading from "../../Components/Loading"
import UserContext from "../../Context/UserContext"
import { getEventDocsById } from "../../Requests/firestore"

export default function ExhEvents() {
  const {currentUser} = useContext(UserContext)
  const [loading, setLoading] = useState(true)

  const [events, setEvents] = useState([])

  const getInfo = useCallback( async () => {
    setLoading(true)
    const res = await getEventDocsById(currentUser.events)
    if(res.status === 'success'){
      setEvents([...res.data])
    }else{
      setEvents([])
    }
    setLoading(false)
  }, [currentUser])
  
  useEffect(()=>{
    if(currentUser !== 'loading'){
      getInfo()
    }
  },[currentUser])

  return (<>
  {
    loading?<Loading/>:<>
    <h1 className="text-5xl flex gap-3 justify-start ml-0 mr-auto items-center">
      <Accent/>
      Eventos
    </h1>
    <div className="mt-4 w-full justify-center grid grid-cols-1">
      {
        events.map((event, index)=>{return(
          <Link to={`/exh/event/${event.id}`} className="big-btn" key={index}>
            <span>{event.name}</span>
          </Link>
        )})
      }
    </div>
    </>
  }
  </>)
}