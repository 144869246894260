import { useState } from "react"
import { updateVotesDoc } from "../../Requests/firestore"

export default function NominationForm({handle, eventId, category, exhId, votes, setVotes}){
  const [newReseller, setNewReseller] = useState({})
  const [saving, setSending] = useState(false)
  const [error, setError] = useState('')

  const handleChange = (e) => {
    const {name, value} = e.target
    setNewReseller({...newReseller, [name]:value})
  }

  const checkDuplicates = () => {
    let check = false
    votes.forEach(vote => {
      if(vote.fiscalId === newReseller.fiscalId){
        check = true
      }
    })
    return check
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSending(true)
    if(checkDuplicates()){
      setError('Ya se voto por este reseller')
    }else{
      const res = await updateVotesDoc(eventId, category, exhId, [...votes, {...newReseller, order: votes.length+1}])
      if(res.status === 'success'){
        setVotes([...votes, {...newReseller, order: votes.length+1}])
        handle()
      }else{
        setError('ha ocurrido un error')
      }
    }
    setSending(false)
  }
  
  return(<>
  <form onSubmit={handleSubmit} className="flex flex-col gap-6 w-[50%] min-w-[350px] max-w-[500px] mx-auto">
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="name" onChange={handleChange} required value={newReseller.name} autoComplete='off'/>
      <label htmlFor="reseller-name">Nombre del reseller</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="registeredName" onChange={handleChange} required value={newReseller.registeredName} autoComplete='off'/>
      <label htmlFor="reseller-name">Razón social</label>
    </div>
    <div className="input-holder">
      <input type="number" id="reseller-name" placeholder=" " name="fiscalId" onChange={handleChange} required value={newReseller.fiscalId} autoComplete='off'/>
      <label htmlFor="reseller-name">ID fiscal</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="contactName" onChange={handleChange} required value={newReseller.contactName} autoComplete='off'/>
      <label htmlFor="reseller-name">Nombre del contacto</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="position" onChange={handleChange} required value={newReseller.position} autoComplete='off'/>
      <label htmlFor="reseller-name">Cargo del contacto</label>
    </div>
    <div className="input-holder">
      <input type="number" id="reseller-name" placeholder=" " name="phone" onChange={handleChange} required value={newReseller.phone} autoComplete='off'/>
      <label htmlFor="reseller-name">Número de teléfono</label>
    </div>
    <div className="input-holder">
      <input type="email" id="reseller-name" placeholder=" " name="email" onChange={handleChange} required value={newReseller.email} autoComplete='off'/>
      <label htmlFor="reseller-name">Email</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="location" onChange={handleChange} required value={newReseller.location} autoComplete='off'/>
      <label htmlFor="reseller-name">Localidad</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="city" onChange={handleChange} required value={newReseller.city} autoComplete='off'/>
      <label htmlFor="reseller-name">Región</label>
    </div>
    {
      error!== ''? <span className="mx-auto text-center">{error}</span>:<></>
    }
    <button className="action-btn mx-auto" disabled={saving}>{saving?'Cargando...':'Aceptar'}</button>
  </form>
  </>)
}