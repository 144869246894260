import { useContext, useState } from "react"
import {HiOutlineEye, HiOutlineEyeOff} from 'react-icons/hi'
import { useNavigate } from "react-router-dom"
import UserContext from "../../Context/UserContext"
import { sendNewPassEmail, signIn } from "../../Requests/auth"
import Modal from '../../Components/PopUps/Modal'

export default function Admin() {
  const {currentUser, url} = useContext(UserContext)
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [showPass, setShowPass] = useState(false)

  const [sending, setSending] = useState(false)
  const [sendingNewPass, setSendingNewPass] = useState(false)

  const [error, setError] = useState('')
  const [errorNewPass, setErrorNewPass] = useState('')

  const [newPassModal, setNewPassModal] = useState(false)
  
  const handleSubmit = async (e) =>{
    e.preventDefault()
    setSending(true)
    const res = await signIn(currentUser.email, password)
    if(res.status === 'success'){
      navigate('/admin')
    }else{
      switch(res.error.code){
        case 'auth/wrong-password':
          setError('La contraseña es incorrecta.')
          break;
        default:
          setError('No se pudo iniciar sesión.')
          break;
      }
    }
    setSending(false)
  }

  const handleNewPassModal = () => {
    setNewPassModal(!newPassModal)
    setErrorNewPass('')
  }

  const sendNewPass = async () => {
    setSendingNewPass(true)
    //console.log(currentUser.email)
    const passRes = await sendNewPassEmail(currentUser.email, url)
    if(passRes.status === 'success'){
      setErrorNewPass('Se envió el mail correctamente.')
    }else{
      setErrorNewPass('Ha ocurrido un error.')
    }
    setSendingNewPass(false)
  }

  return <>
  <form onSubmit={handleSubmit} className='flex flex-col justify-center items-center gap-4'>
    <div className="input-holder">
      <input type={showPass?'text':'password'} placeholder=" " id="admin-password" value={password} onChange={(e)=>{setError('');setPassword(e.target.value)}} autoFocus required/>
      <label htmlFor="admin-password">Contraseña</label>
      <button type="button" className="see" onClick={()=>{setShowPass(!showPass)}}>{showPass?<HiOutlineEye/>:<HiOutlineEyeOff/>}</button>
    </div>
    <button type="button" onClick={handleNewPassModal} className="hover:underline text-right -mt-2">Olvidé mi contraseña</button>
    {
      error!==''?<>
      <span className="text-center mx-auto">{error}</span>
      </>:<></>
    }
    <button className="action-btn" disabled={sending}>{sending?'Cargando...':'acceder'}</button>
  </form>
  <Modal isActive={newPassModal} handle={handleNewPassModal} title='Recuperar contraseña'>
    <div className="flex flex-col gap-4 justify-center text-justify">
      <span>Se enviará un email a {currentUser.email} para renovar la contraseña</span>
      <button className="action-btn mx-auto" disabled={sendingNewPass} onClick={sendNewPass}>{sendingNewPass?'Cargando':'Aceptar'}</button>
      {
        errorNewPass!==''?<>
        <span className="text-center mx-auto">{errorNewPass}</span>
        </>:<></>
      } 
    </div>
  </Modal>
  </>
}