import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import { getAdminDocByEmail, getExhibitorDocByEmail } from "../../Requests/firestore";
import { sendAccessCode } from "../../Requests/sendEmail";

export default function Email(){
  const {setCurrentUser} = useContext(UserContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async (e) =>{
    e.preventDefault()
    setSending(true)
    setError('')
    const exhRes = await getExhibitorDocByEmail(email)
    if(exhRes.status === 'success'){
      if(exhRes.docRef){
        setCurrentUser({email:email, role:'exh'})
        sendAccessCode(email, exhRes.docRef.id)
        navigate('/signin/code', {state:{...exhRes.docRef}})
      }
    }else{
      setError('Ha ocurrido un error')
    }
    const admRes = await getAdminDocByEmail(email)
    if(admRes.status === 'success'){
      if(admRes.docRef){
        setCurrentUser({email:email, role:'admin', ...admRes.docRef})
        if(admRes.docRef.fullName){
          navigate('/signin/admin')
        }else{
          navigate('/signin/newadmin')
        }
      }
    }else{
      setError('Ha ocurrido un error')
    }
    if(admRes.docRef===false && exhRes.docRef === false){
      setError('La cuenta no existe')
    }
    setSending(false)
  }

  return <>
  <form onSubmit={handleSubmit} className='flex flex-col gap-4 justify-center items-center'>
    <div className="input-holder">
        <input type="email" id="sign-in-email" placeholder=" " onChange={(e)=>{setError(''); setEmail(e.target.value)}} autoFocus required/>
        <label htmlFor="sign-in-email">Email</label>
      </div>
      {
        error===''
        ?<></>
        :<span>{error}</span>
      }
      <button className="action-btn" disabled={sending}>{sending?'cargando...':'siguiente'}</button>
  </form>
  </>
}