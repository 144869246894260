
import './ErrorPage.css'

export default function ErrorPage(){
  return <div className="error-holder">
    <h1>La página no existe.</h1>
    <div className="error-triangles">
      <svg viewBox="0 0 75 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 1.99777L73.5 44L0.749996 86.0022L0.75 1.99777Z" stroke="white" strokeWidth="1.5"/>
      </svg>
      <svg viewBox="0 0 75 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 1.99777L73.5 44L0.749996 86.0022L0.75 1.99777Z" stroke="white" strokeWidth="1.5"/>
      </svg>
      <svg viewBox="0 0 75 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 1.99777L73.5 44L0.749996 86.0022L0.75 1.99777Z" stroke="white" strokeWidth="1.5"/>
      </svg>
    </div>
  </div>
}