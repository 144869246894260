//const url = 'http://127.0.0.1:5001/it-partner-awards-web/us-central1/app'
const url = 'https://us-central1-it-partner-awards-web.cloudfunctions.net/app'

export const sendAccessCode = async (email, exhId) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    //console.log(email, exhId)
    const body = JSON.stringify({
      email:email,
      exhId:exhId
    })
    await fetch(url+'/sendAccessCode',{
      method:'post',
      headers:myHeaders,
      body:body,
      redirect:'follow'
    })
    return {status:'success'}
  } catch (error) {
    return({status: 'failed', error: error})
  }
}

export const sendExhInvite = async (email, adminName, eventName, limit) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const body = JSON.stringify({
      email:email,
      adminName:adminName,
      eventName:eventName,
      limit:limit
    })
    const res = await fetch(url+'/sendExhInvite',{
      method:'post',
      headers:myHeaders,
      body:body,
      redirect:'follow'
    })
    //console.log(res)
    return {status:'success'}
  } catch (error) {
    return({status: 'failed', error: error})
  }
}

export const setCampaign = async (eventId) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const body = JSON.stringify({
      eventId:eventId
    })
    const res = await fetch(url+'/newEvent',{
      method:'post',
      headers:myHeaders,
      body:body,
      redirect:'follow'
    })
    //console.log(await res.json())
    return {status:'success'}
  } catch (error) {
    //console.log(error)
    return({status: 'failed', error: error})
  }
}

export const setExhContact = async (email, eventId, seconds) => {
  //console.log('setting contact')
  try {
    let sendDate
    let t = new Date(Date.UTC(1970, 0, 1));
    
    t.setUTCSeconds(seconds);
    const displayNum = (n) => {
      let res = n.toString()
      if(res.length === 1){
        res = `0${n}`
      }
      return res
    }
    sendDate = `${t.getFullYear()}-${displayNum(t.getMonth()+1)}-${displayNum(t.getDate())} 00:00:00`
    //console.log('date code:', sendDate)
    
    
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const body = JSON.stringify({
      email:email,
      eventId:eventId,
      sendDate: sendDate
    })
    //console.log(body)
    const res = await fetch(url+'/newExhContact',{
      method:'post',
      headers:myHeaders,
      body:body,
      redirect:'follow'
    })
    //console.log(await res.json())
    return {status:'success'}
  } catch (error) {
    //console.log(error)
    return({status: 'failed', error: error})
  }
}

export const deleteExhContact = async (eventId, email) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const body = JSON.stringify({
      eventId:eventId,
      email:email
    })
    const res = await fetch(url+'/deleteExhContact',{
      method:'post',
      headers:myHeaders,
      body:body,
      redirect:'follow'
    })
    //console.log(await res.json())
    return {status:'success'}
  } catch (error) {
    //console.log(error)
    return({status: 'failed', error: error})
  }
}

export const deleteCampaign = async (eventId) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const body = JSON.stringify({
      eventId:eventId,
    })
    const res = await fetch(url+'/deleteEvent',{
      method:'post',
      headers:myHeaders,
      body:body,
      redirect:'follow'
    })
    //console.log(await res.json())
    return {status:'success'}
  } catch (error) {
    //console.log(error)
    return({status: 'failed', error: error})
  }
}