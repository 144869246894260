import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import UserContext from "../../Context/UserContext"
import { updateVotesDoc } from "../../Requests/firestore"

export default function EditNomineeForm({nominees, setNominees, index, category, handle}) {
  const {eventId} = useParams()
  const {currentUser} = useContext(UserContext)
  const [nomminee, setNominee] = useState({})
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  useEffect(()=>{
    setNominee(nominees[index])
  },[])

  const handleChange = (e) => {
    const {name, value} = e.target
    setNominee({...nomminee, [name]:value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    let votes = nominees
    votes[index] = nomminee

    const res = await updateVotesDoc(eventId, category, currentUser.id, votes)
    if(res.status==='success'){
      setNominees([...votes])
      handle()
    }else{
      setError('Ha ocurrido un error')
    }
    
    setSaving(false)
  }

  return(<>
  <form onSubmit={handleSubmit} className="flex flex-col gap-6 w-[50%] min-w-[350px] max-w-[500px] mx-auto">
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="name" onChange={handleChange} required value={nomminee.name || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Nombre del reseller</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="registeredName" onChange={handleChange} required value={nomminee.registeredName || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Razón social</label>
    </div>
    <div className="input-holder">
      <input type="number" id="reseller-name" placeholder=" " name="fiscalId" onChange={handleChange} required value={nomminee.fiscalId || ''} autoComplete='off' readOnly/>
      <label htmlFor="reseller-name">ID fiscal</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="contactName" onChange={handleChange} required value={nomminee.contactName || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Nombre del contacto</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="position" onChange={handleChange} required value={nomminee.position || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Cargo del contacto</label>
    </div>
    <div className="input-holder">
      <input type="number" id="reseller-name" placeholder=" " name="phone" onChange={handleChange} required value={nomminee.phone || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Número de teléfono</label>
    </div>
    <div className="input-holder">
      <input type="email" id="reseller-name" placeholder=" " name="email" onChange={handleChange} required value={nomminee.email || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Email</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="location" onChange={handleChange} required value={nomminee.location || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Localidad</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="city" onChange={handleChange} required value={nomminee.city || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Región</label>
    </div>
    {
      error!== ''? <span className="mx-auto text-center">{error}</span>:<></>
    }
    <button className="action-btn mx-auto" disabled={saving}>{saving?'Cargando...':'Aceptar'}</button>
  </form>
  </>)
}