import { useState } from "react"
import { uploadNewLogo } from "../../Requests/storage"

export default function NewLogoForm({handle}){
  const [newLogo, setNewLogo] = useState(false) 
  const [error, setError] = useState('')

  const handleNewLogoSubmit = async (e) => {
    e.preventDefault()
    if(!newLogo){
      setError('No se seleccionó ningún archivo')
    }else{
      const res = await uploadNewLogo(newLogo)
      if(res.status === 'success'){
        handle()
        window.location.reload()
      }else{
        //console.log(res)
        setError('Ha ocurrido un error')
      }
    }
  }

  const handleChange = (e) => {
    setError('')
    if(e.target.files[0].type !== 'image/png'){
      setError('Por favor, utilice el formato sugerido')
    }else{
      setNewLogo(e.target.files[0])
    }
  }
 return(<>
    <form className="flex flex-col gap-4 w-full" onSubmit={handleNewLogoSubmit}>
      <input type="file" id="new-logo-image" accept=".png" onChange={handleChange}/>
      <label htmlFor="new-logo-image" className="text-center cursor-pointer text-xl my-2">Buscar archivo</label>
      {
        error!==''?
        <span className="block mx-auto text-center">{error}</span>
        :<></>
      }
      <button className="action-btn mx-auto">Guardar</button>
      <span className="text-xs text-center">Se recomienda subir ina imagen png con el fondo transparente.</span>
    </form>
  </>)
}