import { useEffect, useState } from "react"
import { TfiDownload } from "react-icons/tfi"
import { useLocation } from "react-router-dom"
import { utils, write } from "xlsx"
import { saveAs } from "file-saver"
import Modal from "../../Components/PopUps/Modal"
import EditVoteForm from "../../Components/Nominee/EditVoteForm"

export default function Nominee () {
  const location = useLocation()
  const [nominees, setNominees] = useState([])
  const [editVoteModal, setEditVoteModal] = useState(false)
  const [votes, setVotes] = useState([])
  const [vote, setVote] = useState({})
  const [voter, setVoter] = useState({})
  const [toEdit, setToEdit] = useState()

  useEffect(()=>{
    if(location.state.fiscalId){
      let nmns = []
      location.state.votes.forEach((n)=>{
        n.votes.forEach(vote=>{
          nmns = [...nmns, {...vote, voter:n.id}]
        })
      })
      const groupArrayObject = nmns.reduce((group, arr) => {
        
        const { fiscalId } = arr;
        
        group[fiscalId] = group[fiscalId] ?? [];
        
        group[fiscalId].push(arr);
        

        return group;
        
      },{});
            
      setNominees([...groupArrayObject[location.state.fiscalId]])
    }else{
      setNominees([])
    }
  },[location])
  
  const downloadContacts  = async () => {
    let toXls = []
    nominees.forEach((nominee)=>{
      toXls = [...toXls, {
        'Nombre del reseller':nominee.name,
        'Razón social':nominee.registeredName,
        'Nombre del contacto':nominee.contactName,
        'Cargo del contacto':nominee.position,
        'Teléfono':nominee.phone,
        'Email':nominee.email,
        'Localidad':nominee.location,
        'Ciudad':nominee.city,
      }]
    })
    const ws = utils.json_to_sheet(toXls)
    const wb = {
      Sheets:{
        'data':ws
      },
      SheetNames:['data']
    }
    const eb = write(wb,{bookType:'xlsx',type:'array'})
    const data = new Blob([eb],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UFT-8'})
    saveAs(data, `${location.state.fiscalId}-resultados.xlsx`)
    
  }
  
  const handleEditVoteModal = (index) => {
    if(parseInt(index)>=0){
      setToEdit(index)
      let {votes} = location.state.votes.find(vote=>vote.id === nominees[index].voter)
      setVotes([...votes])
      let vote = votes.find(vt=>vt.fiscalId === nominees[index].fiscalId)
      setVote({...vote})
      let voter = nominees[index].voter
      setVoter(voter)
    }
    setEditVoteModal(!editVoteModal)
  }

  return(<>
  {
    nominees.length===0?<><span>No se encontraron votos</span></>:<>
    <div className="flex flex-row items-center justify-between">
      <h1 className="text-2xl flex items-center gap-4">Votos para el id fiscal: {location.state.fiscalId}</h1>
      <div className="flex gap-6">
        <button onClick={downloadContacts}><TfiDownload size={25}/></button>
      </div>
    </div>
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th>Reseller</th>
          <th>Nombre</th>
          <th>Email</th>
          <th>Teléfono</th>
        </tr>
      </thead>
      <tbody>
        {
          nominees.map((nmnee, index)=>{return(
            <tr key={index}>
              <td><button className=" hover:underline" onClick={()=>{handleEditVoteModal(index)}}>{nmnee.name}</button></td>
              <td>{nmnee.contactName}</td>
              <td>{nmnee.email}</td>
              <td>{nmnee.phone}</td>
            </tr>
          )})
        }
      </tbody>
    </table>

    <Modal isActive={editVoteModal} handle={()=>{handleEditVoteModal()}} title='Editar voto'>
        <EditVoteForm votes={votes} vote={vote} voter={voter} category={location.state.category} handle={handleEditVoteModal} nominees={nominees} setNominees={setNominees} nominee={toEdit}/>
    </Modal>

    </>
  }
  </>)
}