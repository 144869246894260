/*
create
update
delete
get ** doc/s (where...)
check (if exists)
*/

import {db} from '../firebase.js'
import { addDoc, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore'

// GENERAL //

const setData = (querySnapshot) => {
  let res = []
  querySnapshot.forEach(doc=>{
    res = [...res, {...doc.data(), id:doc.id}]
  })
  return res
}

// GENERAL //


// ADMINS //

export const createAdminDoc = async (admin, uid) => {
  try {
    if(uid){
      const docRef = await setDoc(doc(db, 'admins', uid),{...admin})
      return {status:'success', docRef:docRef}
    }else{
      const docRef = await addDoc(collection(db, 'admins'), {...admin})
      return {status:'success', docRef:docRef}
    }
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const updateAdminDoc = async (eventId, ev) => {
  try {
    await updateDoc(doc(db, 'admins', eventId),{...ev})
    return {status:'success'}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const deleteAdminDoc = async (adminId) => {
  try {
    await deleteDoc(doc(db, 'admins', adminId))
    return {status:'success'}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const getAdminDocByEmail = async (email) => {
  try {
    const q = query(collection(db, 'admins'), where('email', '==', email), limit(1))
    const querySnapshot = await getDocs(q)
    let res = setData(querySnapshot)
    if(res.length !==0){
      const docRef = {...res[0], id: res[0].id}
      return {status:'success', docRef:docRef}
    }else{
      return {status:'success', docRef:false}
    }
  } catch (error) {
    return {status:'failed', error:error}
  }
}

// ADMINS //

// EXHIBITORS //

export const createExhibitorDoc = async (exh) => {
  try{
    const docRef = await addDoc(collection(db, 'exhibitors'),{
      ...exh
    })
    return {status:'success', docRef:docRef}
  }catch(error){
    return {status:'failed', error:error}
  }
}

export const updateExhibitorDoc  = async (exhId, newExh) => {
  try {
    await updateDoc(doc(db, 'exhibitors', exhId),{...newExh})
    return {status:'success'}

  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const updateExhibitorDocEvents = async (exhId, newEvent) => {
  try{
    await updateDoc(doc(db, 'exhibitors', exhId),{
      events: arrayUnion(newEvent)
    })
    return {status:'success'}
  }catch(error){
    return {status:'failed', error:error}
  }
}

export const getExhibitorDoc = async (exhId) => {
  try {
    const docSnap = getDoc(doc(db, 'exhibitors', exhId))
    return {status:'success', data:docSnap}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const getExhibitorDocByEmail = async (email) => {
  try {
    const q = query(collection(db, 'exhibitors'), where('email', '==', email), limit(1))
    const querySnapshot = await getDocs(q)
    let res = setData(querySnapshot)
    if(res.length !==0){
      const docRef = {...res[0], id: res[0].id}
      return {status:'success', docRef:docRef}
    }else{
      return {status:'success', docRef:false}
    }
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const getExhibitorDocByEmailAndCode = async (email, code) => {
  try {
    const q = query(collection(db, 'exhibitors'), where('email', '==', email), where('code', '==', code), limit(1))
    const querySnapshot = await getDocs(q)
    let res = setData(querySnapshot)
    if(res.length !==0){
      const docRef = {...res[0], id: res[0].id}
      return {status:'success', docRef:docRef}
    }else{
      return {status:'success', docRef:false}
    }
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const getExhibitorDocsByEvent = async (eventId) => {
  try {
    const q = query(collection(db, 'exhibitors'), where('events', 'array-contains', eventId))
    const querySnapshot = await getDocs(q)
    const res = setData(querySnapshot)
    return {status:'success', data:res}
  } catch (error) {
    return {status:'failed', error:error}
  }
} 

// EXHIBITORS //

// EVENTS //

export const createEventDoc = async (event) => {
  try{
    const docRef = await addDoc(collection(db, 'events'),{
      ...event
    })
    return {status:'success', docRef:docRef}
  }catch(error){
    return {status:'failed', error:error}
  }
}

export const getEventDoc = async (eventId) => {
  try{
    const docSnap = await getDoc(doc(db, 'events', eventId))
    return {status:'success', data:docSnap}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const getEventDocs = async () => {
  try{
    const q = query(collection(db, 'events'), orderBy('date'))
    const querySnapshot = await getDocs(q)
    const res = setData(querySnapshot)
    return {status:'success', data:res}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const getEventDocsById = async (ids) => {
  try {
    let res = []
    for(const id of ids){
      const docSnap = await getDoc(doc(db, 'events', id))
      if(docSnap.data()){
        res = [...res, {...docSnap.data(), id:docSnap.id}]
      }
    }
    return {status:'success', data:res}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const updateEventDoc = async (eventId, event) => {
  try{
    await updateDoc(doc(db, 'events', eventId), {
      ...event
    })
    return {status:'success'}
  }catch(error){
    return {status:'failed', error:error}
  }
}

export const deleteEvent = async (eventId) => {
  try{
    await deleteDoc(doc(db, 'events', eventId))
    return {status:'success'}
  }catch(error){
    return {status:'failed', error:error}
  }
}

// EVENTS //

// VOTES //
export const getVotesDoc = async (eventId, category, exhId) => {
  try {
    const docSnap = await getDoc(doc(db, `events/${eventId}/${category}/${exhId}`))
    return {status:'success', data:docSnap }
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const updateVotesDoc = async (eventId, category, exhId, votes) => {
  try {
    await setDoc(doc(db, `events/${eventId}/${category}/${exhId}`),{
      votes:[...votes]
    })
    return {status:'success'}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const getVotesDocs = async (eventId, category) => {
  try {
    const q = query(collection(db, `events/${eventId}/${category}`))
    const querySnapshot = await getDocs(q)
    const res = setData(querySnapshot)
    return {status:'success', data:res}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

// VOTES //
