import { useEffect, useState } from "react"
import { TfiCheck, TfiClose } from 'react-icons/tfi'

export default function AreYouSure({isActive, handle, title}) {
  const [active, setActive] = useState(false)
  
  useEffect(()=>{
    setActive(isActive)
  },[isActive])

  return(<>
    {
      active?<>
        <div className="backdrop" onClick={handle}></div>
        <div className="are-you-sure flex flex-col gap-4">
          <h1 className="text-[40px] mx-auto text-center">{title}</h1>
          <span className="mx-auto text-center text-[20px]">Esta acción no puede revertirse</span>
          <div className="flex gap-[100px] mx-auto mt-4">
            <button className="hover:scale-[1.2] hover:text-red-500 duration-200" onClick={handle}><TfiClose size={25}/></button>
            <button className="hover:scale-[1.2] hover:text-[#03F0D4] duration-200" onClick={()=>{handle('accepted')}}><TfiCheck size={25}/></button>
          </div>
        </div>
      </>:<></>
    }
  </>)
}