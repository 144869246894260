import { useContext, useEffect, useRef, useState } from "react"
import { TfiClose } from "react-icons/tfi"
import {IoTriangleSharp} from "react-icons/io5"
import { useParams } from "react-router-dom"
import Accent from "../../Components/Accent"
import NominationForm from "../../Components/ExhEvent/NominationForm"
import Loading from "../../Components/Loading"
import Modal from "../../Components/PopUps/Modal"
import UserContext from "../../Context/UserContext"
import { getEventDoc, getVotesDoc, updateVotesDoc } from "../../Requests/firestore"
import EditNomineeForm from "../../Components/ExhEvent/EditNomineeForm"
import BackBtn from "../../Components/Buttons/BackBtn"

export default function ExhEvent() {
  const {currentUser} = useContext(UserContext)
  const {eventId} = useParams()
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState(null)
  const [votes, setVotes] = useState(null)
  const [category, setCategory] = useState('consumption')

  const [blocked, setBlocked] = useState(false)

  const [nominationModal, setNominationModal] = useState(false)
  const [editNomineeModal, setEditNomineeModal] = useState(false)
  const [toEdit, setToEdit] = useState(0)

  const handleNominationModal = ()=>{
    setNominationModal(!nominationModal)
  }


  const handleEditNomineeModal = (index) => {
    if(index){
      setToEdit(index)
    }

    setEditNomineeModal(!editNomineeModal)
  }

  useEffect(()=>{
    const getInfo = async () => {
      setLoading(true)
      const evRes = await getEventDoc(eventId)
      if(evRes.status === 'success'){
        setEvent(evRes.data.data())
        const vtsRes = await getVotesDoc(eventId, category, currentUser.id)
        if(vtsRes.status === 'success'){
          if(vtsRes.data.data()){
            setVotes([...vtsRes.data.data().votes])
          }else{
            setVotes([])
          }
        }else{
          setVotes([])
        }
      }else{
        setEvent(false)
      }
      setLoading(false)
    }

    if(currentUser!=='loading'){
      getInfo()
    }

  },[currentUser])

  const changeCategory = async (ctgry) => {
    setVotes([])
    setCategory(ctgry)
    const vtsRes = await getVotesDoc(eventId, ctgry, currentUser.id)
        if(vtsRes.status === 'success'){
          if(vtsRes.data.data()){
            setVotes([...vtsRes.data.data().votes])
          }else{
            setVotes([])
          }
        }else{
          setVotes([])
        }
  }

  const deleteNominee = async (nominee) => {
    let newVotes = votes.filter(vote => vote !== nominee)
    newVotes.forEach((vote, index)=>{
      newVotes[index].order = index+1
    })
    const deleteRes = await updateVotesDoc(eventId, category, currentUser.id, newVotes)
    if(deleteRes.status==='success'){
      setVotes([...newVotes])
    }
  }

  const moveUp = async (ogIndex) => {
    let voteList = votes
    let a = voteList[ogIndex]
    a.order = a.order - 1 
    let b = voteList[ogIndex-1]
    b.order = b.order + 1
    voteList[ogIndex] = b
    voteList[ogIndex-1] = a
    setVotes([...voteList])
    await updateVotesDoc(eventId, category, currentUser.id, voteList)
  }

  const moveDown = async (ogIndex) => {
    let voteList = votes
    let a = voteList[ogIndex]
    a.order = a.order + 1 
    let b = voteList[ogIndex+1]
    b.order = b.order - 1
    voteList[ogIndex] = b
    voteList[ogIndex+1] = a
    setVotes([...voteList])
    await updateVotesDoc(eventId, category, currentUser.id, voteList)
  }

  const displayDate = (date) => {
    let t = new Date(Date.UTC(1970, 0, 1));
    t.setUTCSeconds(date);
    return `${t.getDate()}-${t.getMonth()+1}-${t.getFullYear()}`
  }

  useEffect(()=>{
    if(event){
      let today = new Date()
      let limit = new Date(Date.UTC(1970, 0, 1))
      limit.setSeconds(event.voteLimit.seconds)
      if(today<limit){
        setBlocked(false)
      }else{
        setBlocked(true)
      }
    }
  },[event])

  return(<>
  {
    loading?<Loading/>:<>
    <BackBtn path={'/exh'}/>
    <h1 className="text-3xl gap-3 flex justify-start ml-0 mr-auto items-center">
      <Accent/>
    {event.name}</h1>
    <div className="flex flex-wrap gap-6 my-3">
      <span>Tienes hasta {displayDate(event.voteLimit.seconds)} para realizar cambios</span>
    </div> 
    <div className="nav-holder">
      <button className={category==='consumption'?'nav-btn active':'nav-btn'} onClick={()=>{changeCategory('consumption')}}>Nominación Reseller de CONSUMO</button>
      <button className={category==='value'?'nav-btn active':'nav-btn'} onClick={()=>{changeCategory('value')}}>Nominación Reseller de VALOR AGREGADO</button>
    </div>
    <h1 className="text-center font-semibold text-xl my-6">
    {category === 'consumption'?
    <>Nominaciones para mejor canal de consumo</>:''}
    {category === 'value'?
    <>Nominaciones para mejor canal de valor</>:''}
    </h1>
    {
      votes.length === 0 ?<span className="block mx-auto text-center text-xl my-5">No has nominado a nadie todavía</span>
      :<>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="w-[50px]"></th>
            <th>Reseller</th>
            <th>Contacto</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {votes.map((vote, index)=>{return(
          <tr key={index} >
            <td className="flex justify-start w-fit m-0 gap-3">
              {vote.order+'°'}
              <div className="flex items-center text-[15px] gap-2 order-btn-holder">
                {
                  blocked?<></>:<>
                  <button onClick={()=>{moveUp(index)}} disabled={index===0?true:false}>
                    <IoTriangleSharp/>
                  </button>
                  <button onClick={()=>{moveDown(index)}} disabled={index===votes.length-1?true:false}>
                    <IoTriangleSharp className="rotate-180"/>
                  </button>
                  </>
                }
              </div>
            </td>
            <td><button className="hover:underline" disabled={blocked} onClick={()=>{handleEditNomineeModal(index)}}>{vote.name}</button></td>
            <td>{vote.contactName}</td>
            <td>{vote.email}</td>
            <td className="text-red-500">
            {
              blocked?<></>:
              <button onClick={()=>{deleteNominee(vote)}} className='flex items-center justify-end'><TfiClose size={15}/></button>
            }
            </td>
          </tr>
            )})}
        </tbody>
      </table>

      </>
    }
    {
      blocked?<><span className="block mx-auto text-center text-xl mt-5">El período de votación ha terminado</span></>:<>
      {
        votes.length<5?<>
          <button className="action-btn mx-auto my-8 fancy-hover text-xl" onClick={handleNominationModal}>Nominar</button>
        </>:<><span className="block text-center mx-auto">Ya has alcanzado el limite de nominaciones</span></>
      }
      </>
    }
    <Modal isActive={nominationModal} handle={handleNominationModal} title='Nominar a un reseller'>
      <NominationForm handle={handleNominationModal} eventId={eventId} category={category} exhId={currentUser.id} votes={votes} setVotes={setVotes}/>
    </Modal>
    <Modal isActive={editNomineeModal} handle={handleEditNomineeModal} title='Editar reseller'>
      <EditNomineeForm nominees={votes} setNominees={setVotes} index={toEdit} category={category} handle={handleEditNomineeModal}/>
    </Modal>
    </>
  }
  </>)
}