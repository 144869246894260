import { useEffect, useState } from "react"
import { MdAdd } from "react-icons/md"
import { TfiClose } from "react-icons/tfi"
import { Link, useOutletContext, useParams } from "react-router-dom"
import EditVotesForm from "../../Components/Event/EditVotesForm"
import NewExhForm from "../../Components/Event/NewExhForm"
import Loading from "../../Components/Loading"
import Modal from "../../Components/PopUps/Modal"
import { getExhibitorDocsByEvent, updateExhibitorDoc } from "../../Requests/firestore"
import { deleteExhContact } from "../../Requests/sendEmail"

export default function EventExhibitors() {
  const [newExhModal, setNewExhModal] = useState(false)
  
  const {eventId} = useParams()
  const [loading, setLoading] = useState(true)
  const [exhibitors, setExhibitors] = useState([])
  const event = useOutletContext()
  const handleNewExhModal = () => {
    setNewExhModal(!newExhModal)
  }



  useEffect(()=>{
    const getInfo = async () => {
      setLoading(true)
      const exhs = await getExhibitorDocsByEvent(eventId)
        setExhibitors([...exhs.data])
      setLoading(false)
    }
    getInfo()
  },[eventId])

  const deleteExh = async (index) => {
    //console.log(exhibitors[index].id)
    const exhRes = await updateExhibitorDoc(exhibitors[index].id, {events:exhibitors[index].events.filter(ev=>ev!==eventId)})
    if(exhRes.status=== 'success'){
      deleteExhContact(eventId, exhibitors[index].email)
      let exhs = [...exhibitors]
      exhs.splice(index, 1)
      setExhibitors([...exhs])
    }
  }

  return(<>
  {
    loading?<Loading/>:<>
      <table>
        <thead>
          <tr>
            <th colSpan='100%' className="py-4"><button className="action-btn gap-1" onClick={handleNewExhModal}><MdAdd size={25}/>Expositor</button></th>
          </tr>
          <tr>
            {exhibitors.length===0?
            <>
              <th><h1>No se encontraron expositores</h1></th>
            </>:<>
              <th>Nombre completo</th>
              <th>Email</th>
              <th></th>
            </>}
          </tr>
        </thead>
        <tbody>
        {
          exhibitors.map((exh, index)=>{return(
          <tr key={index}>
            <td>
              <Link className="hover:underline" to={`/admin/event/${eventId}/exhibitors/${exh.id}`}>
              {exh.fullName}
              </Link>
            </td>
            <td>{exh.email}</td>
            <td>
              <button onClick={()=>{deleteExh(index)}} className='flex items-center justify-end text-red-500'><TfiClose size={15}/></button>
            </td>
          </tr>
          )})
        }
        </tbody>
      </table>
      <Modal isActive={newExhModal} handle={handleNewExhModal} title='Nuevo votante'>
        <NewExhForm handle={handleNewExhModal} event={event} eventId={eventId} exhibitors={exhibitors} setExhibitors={setExhibitors}/>
      </Modal>
    </>
  }
  </>)
}