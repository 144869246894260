import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { resetPassword } from "../../Requests/auth"

export default function NewPass(){
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [error, setError] = useState('')
  const [sending, setSending] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const useQuery = ()=>{
    return new URLSearchParams(location.search)
  }

  const query = useQuery()


  const handleSubmit = async (e) => {
    e.preventDefault()
    setSending(true)
    if(newPassword === repeatNewPassword){
      const res = await resetPassword(query.get('oobCode'), newPassword)
      //console.log(res)
      if(res.status === 'success'){
        navigate(`/signin`)
      }else{
        switch (res.error.code) {
          case 'auth/weak-password':
            setError('La contraseña debe tener más de 6 caracteres')
            break;
          default:
            setError('No se pudo cambiar la contraseña, intente de nuevo más tarde')
            break;
        }
      }
    }else{
      setError('Las contraseñas son diferentes.')
    }
    setSending(false)
  }
  return(<>
  <form onSubmit={handleSubmit} className='flex flex-col gap-4 w-[250px]'>
  <div className="input-holder">
    <input type="password" name="newPassword" id="new-password" value={newPassword} onChange={(e)=>{setNewPassword(e.target.value); setError('')}} placeholder=' '/>
    <label htmlFor="new-password">Nueva contraseña</label>
  </div>
  <div className="input-holder">
    <input type="password" name="repeatNewPassword" id="repeat-new-password" value={repeatNewPassword} onChange={(e)=>{setRepeatNewPassword(e.target.value); setError('')}} placeholder=' '/>
    <label htmlFor="repeat-new-password">Confirmar contraseña</label>
  </div>
  {
    error!==''?<span className="block mt-auto text-center">{error}</span>:<></>
  }
  <button className="action-btn mx-auto">{sending?'cargando':'Aceptar'}</button>
  </form>
  </>)
}