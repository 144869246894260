import './App.css';
import './firebase.js'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Routes/HomePage';
import ErrorPage from './Routes/ErrorPage';
import AdminPage from './Routes/AdminPage';
import SignInPage from './Routes/SignInPage';
import Email from './Routes/SignIn/Email';
import Code from './Routes/SignIn/Code';
import { UserProvider } from './Context/UserContext';
import { useEffect, useState } from 'react';
import Admin from './Routes/SignIn/Admin';
import Layout from './Routes/Layout';
import Cookies from "universal-cookie/cjs/Cookies"
import AdminEvents from './Routes/AdminPage/AdminEvents';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase.js';
import AdminEvent from './Routes/AdminPage/AdminEvent';
import ExhibitorPage from './Routes/ExhibitorPage';
import ExhEvents from './Routes/ExhibitorPage/ExhEvents';
import { getAdminDocByEmail, getExhibitorDocByEmail } from './Requests/firestore';
import ExhEvent from './Routes/ExhibitorPage/ExhEvent';
import EventExhibitors from './Routes/AdminPage/EventExhibitors';
import EventVotes from './Routes/AdminPage/EventVotes';
import NewAdmin from './Routes/SignIn/NewAdmin';
import Nominee from './Routes/AdminPage/Nominee';
import ExhVotes from './Routes/AdminPage/ExhVotes';
import NewPass from './Routes/SignIn/NewPass';


function App() {
  const [currentUser, setCurrentUser] = useState('loading')

  useEffect(()=>{
    const cookies = new Cookies()
    cookies.set('currentUser', currentUser, {path:'/'})
  },[currentUser])

  
  useEffect(()=>{
    const setUserInfo = async (newUser)=>{
      const admRes = await getAdminDocByEmail(newUser.email)
      if(admRes.status === 'success' && admRes.docRef){
        setCurrentUser({...newUser, ...admRes.docRef, role:'admin'})
      }
      const exhRes = await getExhibitorDocByEmail(newUser.email)
      if(exhRes.status === 'success' && exhRes.docRef){
        setCurrentUser({...newUser, ...exhRes.docRef, role:'exh'})
      }
    }

    onAuthStateChanged(auth, newUser=>{
      if(newUser){
        //console.log('signed in')
        setUserInfo(newUser)
      }else{
        //console.log('signed out')
        setCurrentUser('no-user')
      }
    })
  },[])

  return (
    <UserProvider value={{currentUser, setCurrentUser, url:'https://it-partner-awards-web.web.app'}}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout/>} path='/'>
            <Route index element={<HomePage/>}/>
            <Route element={<SignInPage/>} path='/signin' >
              <Route index element={<Email/>}/>
              <Route path='code' element={<Code/>}/>
              <Route path='admin' element={<Admin/>}/>
              <Route path='newadmin' element={<NewAdmin/>}/>
              <Route path='resetpassword' element={<NewPass/>}/>
            </Route>
            <Route element={<ErrorPage/>} path='*'/>
          </Route>
          <Route element={<AdminPage/>} path='/admin'>
            <Route index element={<AdminEvents/>}/>
            <Route element={<AdminEvent/>} path='event/:eventId'>
              <Route index element={<EventExhibitors/>} path='exhibitors'/>
              <Route element={<ExhVotes/>} path='exhibitors/:exhId'/>
              <Route element={<Nominee/>} path='nominee'/>
              <Route element={<EventVotes/>} path=':category'/>
            </Route>
          </Route>
          <Route element={<ExhibitorPage/>} path='/exh'>
            <Route element={<ExhEvents/>} index/>
            <Route element={<ExhEvent/>} path='event/:eventId'/>
          </Route>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
