import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import UserContext from "../../Context/UserContext"
import { createAccount, signIn } from "../../Requests/auth"
import { getExhibitorDocByEmailAndCode, updateExhibitorDoc } from "../../Requests/firestore"

export default function Code(){
  const navigate = useNavigate()
  const location = useLocation()
  const {currentUser, setCurrentUser} = useContext(UserContext)
  const [codeInput, setCodeInput] = useState(new Array(6).fill(''))
  const [sending, setSending] = useState(false)
  
  
  const checkDate = (seconds) => {
    let today = new Date()
    let date = new Date(1970, 0, 0)
    date.setSeconds(seconds)
    //console.log(today, date)
    if(date>today){
      return true
    }else{
      return false
    }
  }
  
  const handleSubmit = async (e) =>{
    e.preventDefault()
    setSending(true)
    const {email} = location.state
    const code = codeInput.join('')
    const exhRes = await getExhibitorDocByEmailAndCode(email, code)
    if(exhRes.docRef){
      if(checkDate(exhRes.docRef.codeExp.seconds)){
        if(exhRes.docRef.pass){
          const res = await signIn(exhRes.docRef.email, exhRes.docRef.pass)
          if(res.status === 'success'){
            setCurrentUser({...currentUser, ...exhRes.docRef})
            navigate('/exh')
          }
        }else{
          const res = await createAccount(exhRes.docRef.email)
          if(res.status === 'success'){
            const updateRes = await updateExhibitorDoc(exhRes.docRef.id, {pass:res.pass})
            if(updateRes.status === 'success'){
            setCurrentUser({...currentUser, ...exhRes.docRef})
              navigate('/exh')
            }
          }
        }
      }else{
        //console.log('expired')
      }
    }else{
      //console.log('wrong code')
    }
    //console.log(exhRes)
    setSending(false)
  }

  const handleChange = (e) => {
    const {name, value, nextSibling} = e.target
    let digits = [...codeInput]
    digits[name] = value
    setCodeInput([...digits])
    if(nextSibling&&value!==''){
      nextSibling.focus()
    }
  }

  useEffect(()=>{
    if(!location.state.email){
      navigate('/signin')
    }
  },[navigate, location])

  return <>
  <h1 className="font-medium text-center">Le hemos enviado un código por correo, ingréselo para continuar.</h1>
  <form onSubmit={handleSubmit} className='flex flex-col gap-4 justify-center items-center'>
  <div className="code-holder">
    {
      codeInput.map((digit, index)=>{
        return <input type="text" pattern='\d' maxLength='1' key={index} name={index} value={digit} onChange={handleChange} onFocus={(e)=>{e.target.select()}} autoComplete='off'/>

      })
    }
  </div>
  <button className="action-btn" disabled={sending}>{sending?'Cargando...':'acceder'}</button>
  </form>
  </>
}