import { Outlet } from "react-router-dom";
import LogoImage from "../Components/LogoImage";

export default function SignInPage(){
  return <div className="container mx-auto px-4 flex items-center justify-center h-[100vh]">
    <div className="flex flex-col gap-4 justify-center items-center">
      <LogoImage/>
      <Outlet/>
    </div>
  </div>
}