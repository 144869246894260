import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {updateVotesDoc} from "../../Requests/firestore"

export default function EditVoteForm({votes, vote, voter, category, handle}){
  const {eventId} = useParams()
  const [newVote, setNewVote] = useState({})
  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)
  const [voteIndex, setVoteIndex] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    let voteList = [...votes]
    voteList[voteIndex] = {...newVote}

    //console.log('new list', voteList)
    let count = 0
    voteList.forEach(vt=>{
      if(vt.fiscalId === newVote.fiscalId){
        ++count
      }
    })
    if(count>1){
      setError('El expositor ya ha votado a ese ID fiscal')
    }else{
      const updateRes = await updateVotesDoc(eventId, category, voter, voteList)
      if(updateRes.status==='success'){
        setError('')
        let vts = location.state.votes
        let nomination = vts.findIndex(v=>v.id===voter)
        vts[nomination].votes = [...voteList]
        navigate(`/admin/event/${eventId}/nominee`, {state:{...location.state, votes:vts}})
        handle()
      }else{
        setError('Ha ocurrido un error')
      }
      
    }

    setSaving(false)
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setError('')
    setNewVote({...newVote, [name]:value})
  }

  useEffect(()=>{
    setNewVote({...vote})
    setVoteIndex(votes.findIndex((vt)=>vt.fiscalId === vote.fiscalId))
  },[vote])

  return(<>
    <form onSubmit={handleSubmit} className="flex flex-col gap-6 w-[50%] min-w-[350px] max-w-[500px] mx-auto">
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="name" onChange={handleChange} required value={newVote.name || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Nombre del reseller</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="registeredName" onChange={handleChange} required value={newVote.registeredName || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Razón social</label>
    </div>
    <div className="input-holder">
      <input type="number" id="reseller-name" placeholder=" " name="fiscalId" onChange={handleChange} required value={newVote.fiscalId || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">ID fiscal</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="contactName" onChange={handleChange} required value={newVote.contactName || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Nombre del contacto</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="position" onChange={handleChange} required value={newVote.position || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Cargo del contacto</label>
    </div>
    <div className="input-holder">
      <input type="number" id="reseller-name" placeholder=" " name="phone" onChange={handleChange} required value={newVote.phone || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Número de teléfono</label>
    </div>
    <div className="input-holder">
      <input type="email" id="reseller-name" placeholder=" " name="email" onChange={handleChange} required value={newVote.email || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Email</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="location" onChange={handleChange} required value={newVote.location || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Localidad</label>
    </div>
    <div className="input-holder">
      <input type="text" id="reseller-name" placeholder=" " name="city" onChange={handleChange} required value={newVote.city || ''} autoComplete='off'/>
      <label htmlFor="reseller-name">Región</label>
    </div>
    {
      error!== ''? <span className="mx-auto text-center">{error}</span>:<></>
    }
    <button className="action-btn mx-auto" disabled={saving}>{saving?'Cargando...':'Aceptar'}</button>
  </form>
  </>)
}