import { useEffect, useState } from "react"
import {  MdEdit } from "react-icons/md"
import { VscTrash } from 'react-icons/vsc'
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import Accent from "../../Components/Accent"
import BackBtn from "../../Components/Buttons/BackBtn"
import EditEventForm from "../../Components/Event/EditEventForm"
import Loading from "../../Components/Loading"
import AreYouSure from "../../Components/PopUps/AreYouSure"
import Modal from "../../Components/PopUps/Modal"
import Toast from "../../Components/PopUps/Toast"
import { deleteEvent, getEventDoc } from "../../Requests/firestore"
import { deleteCampaign } from "../../Requests/sendEmail"

export default function AdminEvent(){
  const {eventId} = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(()=>{
    if(location.pathname === `/admin/event/${eventId}`){
      navigate(`/admin/event/${eventId}/exhibitors`)
    }
  },[location, navigate])

  const [loading, setLoading] = useState(true)

  const [event, setEvent] = useState({})

  const [deleteEventModal, setDeleteEventModal] = useState(false)  
  const [editModal, setEditModal] = useState(false)

  const [toast, setToast] = useState(false)

  const handleDeleteEventModal = async (res) =>{
    if(res==='accepted'){
      const res = await deleteEvent(eventId)
      if(res.status==='success'){
        await deleteCampaign(eventId)
        navigate('/admin', {state:{deleted:true}})
      }else{
        handleToast()
      }
    }
    setDeleteEventModal(!deleteEventModal)
  }

  const handleEditModal = () => {
    setEditModal(!editModal)
  }

  const handleToast = () => {
    setToast(true)
    setTimeout(()=>{
      setToast(false)
    },3000)
  }

  const displayDate = (date) => {
    let t = new Date(Date.UTC(1970, 0, 1));
    t.setUTCSeconds(date);
    return `${t.getDate()}-${t.getMonth()+1}-${t.getFullYear()}`
  }

  useEffect(()=>{
    const getInfo = async () => {
      setLoading(true)
      const ev = await getEventDoc(eventId)
      if(ev.status === 'success'&&ev.data.data()){
        setEvent({...ev.data.data()})
      }else{
        setEvent(false)
      }
      setLoading(false)
    }

    getInfo()
  },[eventId])

  return(<>
  {
    loading?<>
      <Loading/>
    </>:<>
    {
      event?
      <>
      <BackBtn path={'/admin'}/>
    <h1 className="text-3xl flex gap-2 justify-between ml-0 mr-auto items-center">
      <div className="flex items-center gap-2">
        <Accent/>
        {event.name}
      </div>
      <div className="flex felx-row gap-2">
        <button className="hover:text-[#03F0D4] duration-200" onClick={handleEditModal}><MdEdit size={25}/></button>
        <button className="hover:text-red-500 duration-200" onClick={handleDeleteEventModal}><VscTrash/></button>
      </div>
    </h1>
    <div className="flex flex-wrap gap-6">
      <span>Fecha: {displayDate(event.date.seconds)}</span>
      <span>Límite: {displayDate(event.voteLimit.seconds)}</span>
    </div> 
    <div className="nav-holder my-3">
      <NavLink to={`/admin/event/${eventId}/exhibitors`} className={({isActive})=>isActive?'nav-btn active':'nav-btn'}>Expositores</NavLink>
      <NavLink to={`/admin/event/${eventId}/consumption`} className={({isActive})=>isActive?'nav-btn active':'nav-btn'}>Nominación Reseller CONSUMO</NavLink>
      <NavLink to={`/admin/event/${eventId}/value`} className={({isActive})=>isActive?'nav-btn active':'nav-btn'}>Nominación Reseller VALOR AGREGADO</NavLink>
    </div>
    <Outlet context={event}/>
    <Modal isActive={editModal} handle={handleEditModal} title='Editar evento'>
      <EditEventForm handle={handleEditModal} eventId={eventId} event={event} setEvent={setEvent}/>
    </Modal>
    <AreYouSure isActive={deleteEventModal} handle={handleDeleteEventModal} title='¿Eliminar evento?'/>
    <Toast isActive={toast} title={'Error: no se ha podido eliminar el evento'}/>
    </>:<>
    <h1 className="text-3xl flex gap-2 justify-between ml-0 mr-auto items-center">
    No se econtró el evento
    </h1>
    </>
  }
    </>
  }
  </>)
}