import { confirmPasswordReset, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut as signOut_ } from 'firebase/auth'
import { auth } from '../firebase'
export const signIn = async (email, pass) => {
  try{
    const userCredentials = await signInWithEmailAndPassword(auth, email, pass)
    return {status:'success', user:userCredentials.user}
  }catch (error){
    //console.log(error)
    return {status:'failed', error:error}
  }
}

const generateToken = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let token = ''
  for(let i=0;i<30; i++){
    token += chars[Math.floor(Math.random() * chars.length)];
  }
  return token
}

export const createAccount = async (email) => {
  try {
    const pass = generateToken()
    const userCredentials = await createUserWithEmailAndPassword(auth, email, pass)
    return {status:'success', user:userCredentials.user, pass:pass}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const createAdminAccount = async (email, pass) => {
  try {
    const userCredentials  = await createUserWithEmailAndPassword(auth, email, pass)
    return {status:'success', user:userCredentials.user}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const signOut = async () => {
  try{
    await signOut_(auth)
    return {status:'success'}
  }catch (error){
    return {status:'failed', error:error}
  }
}

export const sendNewPassEmail = async (email, url) => {
  try {
    await sendPasswordResetEmail(auth, email, {url:`${url}/signin`})
    return {status:'success'}
  } catch (error) {
    return {status:'failed', error:error}
  }
}

export const resetPassword = async (oobCode, newPassword) =>{
  try {
    await confirmPasswordReset(auth, oobCode, newPassword)
    return {status:'success'}
  } catch (error) {
    return {status:'failed', error:error}
  }
}