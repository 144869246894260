import { useEffect, useState } from "react"
import { RiCloseFill } from 'react-icons/ri'
import './styles.css'
export default function Modal({isActive, children, handle, title, size}) {
  const [active, setActive] = useState(false)
  const [s, setSize] = useState('')

  useEffect(()=>{
    switch(size){
      case 'lg':
        setSize('lg')
        break;
      case 'md':
        setSize('md')
        break;
      case 'sm':
        setSize('sm')
        break;
      default:
        setSize('md')
        break;
    }
  },[size])


  useEffect(()=>{
    setActive(isActive)
  },[isActive])
  return (<>
  {
    active?<>
    <div className="backdrop" onClick={handle}></div>
    <div className={`modal ${s}`}>
    <div className="sticky z-10 top-0 flex items-center justify-between py-4 px-8 bg-black">
          <h1 className="font-normal text-xl">
            {title}
          </h1>
          <button className="block text-red-600" onClick={()=>{handle()}}>
            <RiCloseFill size={25}/>
          </button>
        </div>
        <div className="modal-children">
          {children}
        </div>
    </div>
    </>:<></>
  }
  </>)
}