import { Outlet } from "react-router-dom";
import ElementOne from "../Components/Background/ElementOne";
import ElementTwo from "../Components/Background/ElementTwo";

export default function Layout(){
  return (<>
    <div className="absolute w-full h-full -z-50">
    <ElementOne/>
    <ElementTwo/>
    </div>
    <Outlet/>
  </>)
}