import { useEffect, useState } from "react"
import { updateEventDoc } from "../../Requests/firestore"

export default function EditEventForm({handle, eventId, event, setEvent}) {
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')
  const [editEvent, setEditEvent] = useState({})

  useEffect(()=>{
    const setDate = (seconds) => {
      let t = new Date(Date.UTC(1970, 0, 1));
      t.setUTCSeconds(seconds);
      let month = t.getMonth()+1
      let day = t.getDate()
  
      if(month < 10){
        month = '0' + month.toString()
      }
      if(day < 10){
        day = '0' + day.toString()
      }
  
      //console.log(month, day)
  
      let date = `${t.getFullYear()}-${month}-${day}`
      return date
    }
    setEditEvent({...event, date: setDate(event.date.seconds), voteLimit:setDate(event.voteLimit.seconds)})

  },[event])

  const handleInput = (e) => {
    const {value, name} = e.target
    setError('')
    setEditEvent({...editEvent, [name]:value})
  }

  const handleSubmitEdit = async (e) => {
    e.preventDefault()
    //console.log(editEvent)
    setSending(true)
    const date = new Date(editEvent.date)
    date.setDate(date.getDate()+1)
    const voteLimit = new Date(editEvent.voteLimit)
    voteLimit.setDate(voteLimit.getDate()+1)
    const today = new Date()
    if(date > today && voteLimit > today){
      if(date > voteLimit){         
        
        const res = await updateEventDoc(eventId ,{...editEvent, date: date, voteLimit: voteLimit})
        if(res.status === 'success'){
          setEvent({...editEvent, date: {seconds: Math.round(date.getTime() / 1000)}, voteLimit: {seconds: Math.round(voteLimit.getTime() / 1000)}})
          handle()
        }else{
          //console.log(res.error)
          setError('Ha ocurrido un error.')
        }
        
      }else{
        setError('La fecha límite debe ser previa a la fecha del evento')
      }
    }else{
      setError('Las fechas no son válidas')
    }
    setSending(false)
  }


  return (<>
  <form onSubmit={handleSubmitEdit} className='flex flex-col gap-6 justify-center'>
      <div className="input-holder">
        <input type="text" name="name" id="new-event-name" placeholder=" " onChange={handleInput} value={editEvent.name || ''} required/>
        <label htmlFor="new-event-name">Nombre</label>
      </div>
      <div className="input-holder">
        <input type="date" name="voteLimit" id="new-event-limit" placeholder=" " onChange={handleInput} value={editEvent.voteLimit || ''} required/>
        <label htmlFor="new-event-limit">Fecha límite</label>
      </div>
      <div className="input-holder">
        <input type="date" name="date" id="new-event-date" placeholder=" " onChange={handleInput} value={editEvent.date || ''} required/>
        <label htmlFor="new-event-date">Fecha del evento</label>
      </div>
      {
        error!==''?<>
        <span className="mx-auto text-center">{error}</span>
        </>:<></>
      }
      <button className="action-btn mx-auto" disabled={sending}>{sending?'Guardando':'Guardar'}</button>
    </form>
  </>)
}