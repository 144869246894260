import { useState } from "react"
import { createEventDoc } from "../../Requests/firestore"
import { setCampaign } from "../../Requests/sendEmail";

export default function NewEventForm({handle, setEvents, events}) {
  const [newEvent, setNewEvent] = useState({})

  const [error, setError] = useState('')

  const [sending, setSending] = useState(false)

  const handleInput = (e) => {
    const {value, name} = e.target
    setError('')
    setNewEvent({...newEvent, [name]:value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSending(true)
    const date = new Date(newEvent.date)
    date.setDate(date.getDate()+1)
    const voteLimit = new Date(newEvent.voteLimit)
    voteLimit.setDate(voteLimit.getDate()+1)
    const today = new Date()
    if(date > today && voteLimit > today){
      if(date>voteLimit){         
        const res = await createEventDoc({...newEvent, date: date, voteLimit: voteLimit})
        if(res.status === 'success'){
          await setCampaign(res.docRef.id)
          setEvents([...events, {...newEvent,id:res.docRef.id, date: {seconds: Math.round(date.getTime() / 1000)}, voteLimit: {seconds: Math.round(voteLimit.getTime() / 1000)}}])
          handle()
        }else{
          setError('Ha ocurrido un error.')
        }
      }else{
        setError('La fecha límite debe ser previa a la fecha del evento')
      }
    }else{
      setError('Las fechas no son válidas')
    }
    setSending(false)
  }

  return (<>
    <form onSubmit={handleSubmit} className='flex flex-col gap-6 justify-center'>
      <div className="input-holder">
        <input type="text" name="name" id="new-event-name" placeholder=" " onChange={handleInput} value={newEvent.name || ''} required/>
        <label htmlFor="new-event-name">Nombre</label>
      </div>
      <div className="input-holder">
        <input type="date" name="date" id="new-event-date" placeholder=" " onChange={handleInput} value={newEvent.date || ''} required/>
        <label htmlFor="new-event-date">Fecha del evento</label>
      </div>
      <div className="input-holder">
        <input type="date" name="voteLimit" id="new-event-limit" placeholder=" " onChange={handleInput} value={newEvent.voteLimit || ''} required/>
        <label htmlFor="new-event-limit">Fecha límite</label>
      </div>
      {
        error!==''?<>
        <span className="mx-auto text-center">{error}</span>
        </>:<></>
      }
      <button className="action-btn mx-auto" disabled={sending}>{sending?'Guardando':'Guardar'}</button>
    </form>
  </>)
}