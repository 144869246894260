import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Loading from "../../Components/Loading"
import { getExhibitorDoc, getVotesDoc } from "../../Requests/firestore"
import Modal from "../../Components/PopUps/Modal"
import EditVotesForm from "../../Components/Event/EditVotesForm"

export default function ExhVotes(){
  const {exhId, eventId} = useParams()
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState('consumption')
  const [exh, setExh] = useState({})
  const [votes, setVotes] = useState([])
  const [toEdit, setToEdit] = useState(0)

  const [editVoteModal, setEditVoteModal] = useState(false)

  const handleEditVoteModal = (index) => {
    if(parseInt(index)>=0){
      setToEdit(index)
    }
    
    setEditVoteModal(!editVoteModal)
  }

  useEffect(()=>{
    const getInfo = async () => {
      setLoading(true)
      const exhRes = await getExhibitorDoc(exhId)
      if(exhRes.status === 'success'){
        setExh((await exhRes.data).data())
        const vtsRes = await getVotesDoc(eventId, category, exhId)
        if(vtsRes.data.data()){
          setVotes([...vtsRes.data.data().votes])
        }else{
          setVotes([])
        }
      }
      setLoading(false)
    }

    getInfo()

  },[])

  const changeCategory = async (ctgry) => {
    setVotes([])
    setCategory(ctgry)
    const vtsRes = await getVotesDoc(eventId, ctgry, exhId)
        if(vtsRes.status === 'success'){
          if(vtsRes.data.data()){
            setVotes([...vtsRes.data.data().votes])
          }else{
            setVotes([])
          }
        }else{
          setVotes([])
        }
  }


  return(<>
  {
    loading?<Loading/>:<>
    {
      exh?<>
      <h1 className="text-2xl my-3">Votos de {exh.fullName}</h1>
      <div className="nav-holder my-4">
      <button className={category==='consumption'?'nav-btn active':'nav-btn'} onClick={()=>{changeCategory('consumption')}}>Nominación Reseller de CONSUMO</button>
      <button className={category==='value'?'nav-btn active':'nav-btn'} onClick={()=>{changeCategory('value')}}>Nominación Reseller de VALOR AGREGADO</button>
    </div>
      <table>
        <thead>
          <tr>
            <th>Reseller</th>
            <th>Contacto</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {
            votes.map((vt, index)=>{return(
              <tr key={index}>
                <td><button className="hover:underline" onClick={()=>{handleEditVoteModal(index)}}>{vt.name}</button></td>
                <td>{vt.contactName}</td>
                <td>{vt.email}</td>
              </tr>
            )})
          }
        </tbody>
      </table>
      </>
      :<>
      <span>No se encontró el expositor</span>
      </>
    }
    <Modal isActive={editVoteModal} handle={()=>{handleEditVoteModal()}} title='Editar nominación'>
      <EditVotesForm handle={()=>{handleEditVoteModal()}} vote={toEdit} votes={votes} setVotes={setVotes} category={category}/>
    </Modal>
    </>
  }
  </>)
}