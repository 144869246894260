import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import UserContext from "../../Context/UserContext"
import { createAdminAccount } from "../../Requests/auth"
import { createAdminDoc, deleteAdminDoc } from "../../Requests/firestore"

export default function NewAdmin(){
  const navigate = useNavigate()
  const {currentUser} = useContext(UserContext)

  const [newAdmin, setNewAdmin] = useState({})

  const [saving, setSaving] = useState(false)

  const [error, setError] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    //console.log(currentUser)
    if(newAdmin.password === newAdmin.repeatPassword){
      const accRes = await createAdminAccount(currentUser.email, newAdmin.password)
      if(accRes.status === 'success'){
        await deleteAdminDoc(currentUser.id)
        await createAdminDoc({fullName:newAdmin.fullName, email:currentUser.email}, accRes.user.uid)
        navigate('/admin')
      }else{
        //console.log(accRes.error)
        switch (accRes.error.code) {
          case 'auth/weak-password':
            setError('Su contraseña es insegura')
            break;
          default:
            setError('Ha ocurrido un error')
            break;
        }
      }
    }else{
      setError('Las contraseñas ingresadas no son iguales')
    }
    setSaving(false)
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setError('')
    setNewAdmin({...newAdmin, [name]:value})
  }

  return(<>
  <form onSubmit={handleSubmit} className='flex flex-col gap-4 w-full px-[40px]'>
    <div className="input-holder">
      <input type="text" id="new-admin-fullName" name="fullName" value={newAdmin.fullName||''} onChange={handleChange} required placeholder=" "/>
      <label htmlFor="new-admin-fullName">Nombre completo</label>
    </div>
    
    <div className="input-holder">
      <input type="password" id="new-admin-pass" name="password" value={newAdmin.password||''} onChange={handleChange} required placeholder=" "/>
      <label htmlFor="new-admin-pass">Contraseña</label>
    </div>
    <div className="input-holder">
      <input type="password" id="new-admin-repeat-pass" name="repeatPassword" value={newAdmin.repeatPassword||''} onChange={handleChange} required placeholder=" "/>
      <label htmlFor="new-admin-repeat-pass">Confirmar contraseña</label>
    </div>
    {
      error!==''?<span className="block mx-auto text-center">{error}</span>:<></>
    }
    <button className="action-btn mx-auto" disabled={saving}>{saving?'Cargando':'Aceptar'}</button>
  </form>
  </>)
}