import { ref, uploadBytes } from "firebase/storage"
import { storage } from "../firebase"

export const uploadNewLogo = async (newLogo) => {
  try {
    const fileRef = ref(storage, 'logo/logo.png')
    await uploadBytes(fileRef, newLogo)
    return {status:'success'}
  } catch (error) {
    return {status:'failed', error:error}
  }
}
