import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Nav from "../Components/Nav";

import UserContext from "../Context/UserContext";
export default function AdminPage() {
  const {currentUser} = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(()=>{
    switch (currentUser) {
      case 'loading':
        //console.log('the user ingo is getting fetchewd')
        break;
      case 'no-user':
        //console.log('you arre not logged in, get out')
        navigate('/')
        break;
      default:
        if(currentUser.role!=='admin'){
          //console.log('you are not an admin, get out')
          navigate('/')

        }else{
          //console.log('you are an admin')
        }
        break;
    }
  },[currentUser])

  return <>
    <Nav isAdmin={true}/>
    <div className="container mx-auto px-4 ">
      <Outlet/>
    </div>
  </>
}