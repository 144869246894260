import { useEffect, useState } from "react"

export default function Toast({isActive, title}) {
  const [active, setActive] = useState(false)
  
  useEffect(()=>{
    setActive(isActive)
  },[isActive])

  return(<>
  {
    active?
      <div className="toast">
        <h1 className="text-black">{title}</h1>
      </div>
    :<></>
  }
  </>)
}