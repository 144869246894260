import { useState } from "react";
import { MdEdit } from "react-icons/md";
import {BiUserPlus} from "react-icons/bi"
import LogOut from "./Buttons/LogOut";
import LogoImage from "./LogoImage";
import Modal from "./PopUps/Modal"
import "./Nav.css"
import NewLogoForm from "./Nav/NewLogoForm";
import NewAdminForm from "./Nav/NewAdminForm";
import Manual from "./Buttons/Manual";

export default function Nav({isAdmin}){

  const [changeLogoModal, setChangeLogoModal] = useState(false)

  const [newAdminModal, setNewAdminModal] = useState(false)

  const handleNewAdminModal = ()=>{
    setNewAdminModal(!newAdminModal)
  }

  const handleChangeLogoModal = () => {
    setChangeLogoModal(!changeLogoModal)
  }

  return(
    <nav className="flex items-center justify-between px-[20px] py-[10px]">
    {
      isAdmin?<>
      <div className="flex flex-row gap-4 items-center">
      <button className="nav-logo edit" onClick={handleChangeLogoModal}>
        <LogoImage/>
        <span>
          <MdEdit/>
        </span>
      </button>
      <button className="flex items-center h-fit" onClick={handleNewAdminModal}>
        <BiUserPlus size={30}/>
      </button>
      </div>
      </>
      :
      <div className="nav-logo">
        <LogoImage/>
      </div>
    }
    <div className="flex items-center gap-4">
      <Manual/>
      <LogOut/>
    </div>
      <Modal isActive={changeLogoModal} handle={handleChangeLogoModal} title={'Actualizar logo'}>
        <NewLogoForm handle={handleChangeLogoModal}/>
      </Modal>
      <Modal isActive={newAdminModal} handle={handleNewAdminModal} title='Registrar nuevo administrador'>
        <NewAdminForm handle={handleNewAdminModal}/>
      </Modal>
    </nav>
  )
}