import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoImage from '../Components/LogoImage'
import UserContext from '../Context/UserContext'
import { getAdminDocByEmail, getExhibitorDocByEmail } from '../Requests/firestore'

export default function HomePage(){
  const {currentUser, setCurrentUser} = useContext(UserContext)

  const navigate = useNavigate()

  const setPath = async () => {
    let path
    if(currentUser.uid){

      const exhRes = await getExhibitorDocByEmail(currentUser.email)
      if(exhRes.status === 'success' && exhRes.docRef){
        setCurrentUser({...currentUser, role:'exh', ...exhRes.docRef})
        path = '/exh'
      }
      const orgRes = await getAdminDocByEmail(currentUser.email)
      if(orgRes.status === 'success' && orgRes.docRef){
        setCurrentUser({...currentUser, role:'admin', ...exhRes.docRef})
        path = '/admin'
      }
    }else{
      path = '/signin'
    }
    if(currentUser!=='loading'){
      navigate(path)
    }
    
  }


  return <div className="container mx-auto px-4">
    <div className="box-middle responsive-size flex flex-col justify-center items-center gap-6">
      <LogoImage/>
      <button className='action-btn fancy-hover mx-auto' onClick={setPath}>Ingresar</button>
    </div>
  </div>
}