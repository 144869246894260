import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Nav from "../Components/Nav";
import UserContext from "../Context/UserContext";

export default function ExhibitorPage(){
  const navigate = useNavigate()
  const {currentUser} = useContext(UserContext)

  useEffect(()=>{
    switch (currentUser){
      case 'loading':
        //console.log('the user ingo is getting fetchewd')
        break;
      case 'no-user':
        //console.log('you arre not logged in, get out')
        navigate('/')
        break;
      default:
        if(currentUser.role!=='exh'){
          //console.log('you are not an admin, get out')
          navigate('/')
        }else{
          //console.log('you are an exhibitor')
        }
        break;
    }
  },[currentUser])


  return(<>
  <Nav/>
  <div className="container mx-auto px-4">
    <Outlet/>
  </div>
  </>
  )
}