import { useContext, useEffect, useState } from "react"
import { createExhibitorDoc, getAdminDocByEmail, getExhibitorDocByEmail, updateExhibitorDocEvents } from "../../Requests/firestore"
import { sendExhInvite, setExhContact } from "../../Requests/sendEmail"
import UserContext from "../../Context/UserContext"

export default function NewExhForm({handle, eventId, exhibitors, setExhibitors, event}) {
  const {currentUser} = useContext(UserContext)
  const [sending, setSending] = useState(false)

  const [newExh, setNewExh] = useState({})

  const [error, setError] = useState('')

  const displayDate = (date) => {
    let t = new Date(Date.UTC(1970, 0, 1));
    t.setUTCSeconds(date);
    return `${t.getDate()}/${t.getMonth()+1}/${t.getFullYear()}`
  }

  const sendMail = async () => {
    const mailRes = await sendExhInvite(newExh.email, currentUser.fullName, event.name, displayDate(event.voteLimit.seconds))
    return mailRes
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    setSending(true)
    const admin = await getAdminDocByEmail(newExh.email)
    if(admin.docRef){
      setError('El mail ya está registrado como administrador')
    }else{
      const exh = await getExhibitorDocByEmail(newExh.email)
      if(exh.docRef){
        if(exh.docRef.events.includes(eventId)){
          setError('El mail ingresado ya está registrado como expositor')
        }else{
          const res = await updateExhibitorDocEvents(exh.docRef.id, eventId)
          if(res.status==='success'){
            await setExhContact(newExh.email, eventId, event.voteLimit.seconds)
            setExhibitors([...exhibitors, {...newExh, events:[eventId]}])
            const mailRes = await sendMail()
            if(mailRes.status === 'success'){
              handle()
            }else{
              setError('No se pudo eviar la notificación')
            }
          }else{
          setError('Ha ocurrido un error')
          }
        }
      }else{
        const res = await createExhibitorDoc({...newExh, events:[eventId]})
        if(res.status === 'success'){
          setExhibitors([...exhibitors, {...newExh, events:[eventId]}])
          const mailRes = await sendMail()
          await setExhContact(newExh.email, eventId, event.voteLimit.seconds)
            if(mailRes.status === 'success'){
              handle()
            }else{
              setError('No se pudo eviar la notificación')
            }
        }else{
          setError('Ha ocurrido un error')
        }
      }
    }
    setSending(false)
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setError('')
    setNewExh({...newExh, [name]:value})
  }

  return(<>
  <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
    <div className="input-holder">
      <input name="fullName" type="text" id="new-exh-fullName" placeholder=" " value={newExh.fullName || ''} onChange={handleChange} required/>
      <label htmlFor="new-exh-fullName">Nombre completo</label>
    </div>
    <div className="input-holder">
      <input name="email" type="email" id="new-exh-email" placeholder=" " value={newExh.email || ''} onChange={handleChange} required/>
      <label htmlFor="new-exh-email">Email</label>
    </div>
    <div className="input-holder">
      <input name="company" type="text" id="new-exh-company" placeholder=" " value={newExh.company || ''} onChange={handleChange} required/>
      <label htmlFor="new-exh-company">Empresa</label>
    </div>
    {
      error!==''?<span className="mx-auto text-center">{error}</span>:<></>
    }
    <button className="action-btn mx-auto" disabled={sending}>{sending?'Cargando':'Agregar'}</button>
  </form>
  </>)
}