import { useState } from "react"
import { createAdminDoc, getAdminDocByEmail, getExhibitorDocByEmail } from "../../Requests/firestore"

export default function NewAdminForm ({handle}) {
  
  const [email, setEmail] = useState('')

  const [saving, setSaving] = useState(false)

  const [error, setError] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    const exhRes = await getExhibitorDocByEmail(email)
    //console.log(exhRes)
    const admRes = await getAdminDocByEmail(email)
    //console.log(admRes)
    if(admRes.status==='failed'||exhRes.status==='failed'){
      setError('Ha ocurrido un error.')
    }else{
      if(admRes.docRef || exhRes.docRef){
        setError('La dirección ingresada ya está en uso.')
      }else{
        //console.log('new user')
        const userRes = await createAdminDoc({email:email})
        if(userRes.status === 'success'){
          handle()
        }else{
          setError('Ha ocurrido un error.')
        }
      }
    }
    //console.log(email)
    setSaving(false)
  }

  return(<>
  <form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
    <div className="input-holder">
      <input type="email" id="new-admin-email" value={email} onChange={(e)=>{setError(''); setEmail(e.target.value)}} placeholder=' ' required/>
      <label htmlFor="new-admin-email">Email</label>
    </div>
    {
      error!==''?<><span className="block mx-auto text-center">{error}</span></>:<></>
    }
    <button disabled={saving} className="action-btn mx-auto">{saving?'Cargando':'Aceptar'}</button>
  </form>
  </>)
}