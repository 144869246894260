// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore'
import {getStorage} from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1n9XWAPYkyLHcyZdpLwcTMehrg4t7E5M",
  authDomain: "it-partner-awards-web.firebaseapp.com",
  projectId: "it-partner-awards-web",
  storageBucket: "it-partner-awards-web.appspot.com",
  messagingSenderId: "247233576365",
  appId: "1:247233576365:web:e5e918dd6bdbc37191f524"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)