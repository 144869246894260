import { useEffect, useState } from "react"
import { useOutletContext, useParams, Link } from "react-router-dom"
import Loading from "../../Components/Loading"
import { getVotesDocs } from "../../Requests/firestore"
import {IoReloadOutline} from "react-icons/io5"
import { TfiDownload } from "react-icons/tfi"
import { utils, write } from "xlsx"
import { saveAs } from "file-saver"

export default function EventVotes() {
  const {eventId, category} = useParams()

  const event = useOutletContext()

  const [votes, setVotes] = useState([])
  const [nominees, setNominees] = useState([])

  const [loading, setLoading] = useState(true)


  const countVotes = (votes) => {
    let nmns = []
    votes.forEach((n)=>{
      nmns = [...nmns, ...n.votes]
    })

    const groupArrayObject = nmns.reduce((group, arr) => {
 
      const { fiscalId } = arr;
    
      group[fiscalId] = group[fiscalId] ?? [];
    
      group[fiscalId].push(arr);
    
      return group;
    
    },{});
    let countedVotes = []
    for(const id in groupArrayObject){
      let points = 0
      groupArrayObject[id].forEach((nomination)=>{
        points = points + 6 - nomination.order
      })
      countedVotes = [...countedVotes, {...groupArrayObject[id][0], votes:points}]
    }

    countedVotes.sort((a,b) => (a.votes < b.votes) ? 1 : ((b.votes < a.votes) ? -1 : 0))

    setNominees([...countedVotes])
  }

  const reloadVotes = async () => {
    setLoading(true)
    const votesRes = await getVotesDocs(eventId, category)
    countVotes(votesRes.data)
    setLoading(false)
  }

  const downloadVotes = async () => {
    let toXls = []
    nominees.forEach((nominee)=>{
      toXls = [...toXls, {
        'Nombre del reseller':nominee.name,
        'Razón social':nominee.registeredName,
        'ID fiscal':nominee.fiscalId,
        'Nombre del contacto':nominee.contactName,
        'Cargo del contacto':nominee.position,
        'Teléfono':nominee.phone,
        'Email':nominee.email,
        'Localidad':nominee.location,
        'Ciudad':nominee.city,
        'Puntos':nominee.votes,
      }]
    })
    const ws = utils.json_to_sheet(toXls)
    const wb = {
      Sheets:{
        'data':ws
      },
      SheetNames:['data']
    }
    const eb = write(wb,{bookType:'xlsx',type:'array'})
    const data = new Blob([eb],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UFT-8'})
    saveAs(data, `${event.name}-${category}.xlsx`)
  }

  useEffect(()=>{
    const getInfo = async () => {
      setLoading(true)
      const votesRes = await getVotesDocs(eventId, category)
      setVotes([...votesRes.data])
      countVotes(votesRes.data)
      setLoading(false)
    }
    getInfo()
  },[eventId, category])

  return(<>
  {
    loading?
      <Loading/>
      :<>
    <div className="flex flex-row items-center justify-between">
      <h1 className="text-2xl flex items-center gap-4">Votos</h1>
      <div className="flex gap-6">
        <button onClick={reloadVotes} ><IoReloadOutline size={25}/></button>
        <button onClick={downloadVotes}><TfiDownload size={25}/></button>
      </div>
    </div>
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th></th>
          <th>Nombre</th>
          <th>Email</th>
          <th>Votos</th>
        </tr>
      </thead>
      <tbody>
        {
          nominees.length===0?<>
            <tr>
              <td colSpan='100%'>No hay votos</td>
            </tr>
          </>:<>
            {
              nominees.map((nmnee, index)=>{return(
                <tr key={index}>
                  <td>{index+1}°</td>
                  <td><Link to={`/admin/event/${eventId}/nominee`} className='hover:underline' state={{votes, fiscalId:nmnee.fiscalId, category:category}}>{nmnee.name}</Link></td>
                  <td>{nmnee.email}</td>
                  <td>{nmnee.votes}</td>
                </tr>
              )})
            }
          </>
        }
      </tbody>
    </table>

    </>
  }
  </>)
}