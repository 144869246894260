import './Loading.css'

export default function Loading () {
  return(
    <div className='loading-holder'>
      <svg viewBox="0 0 75 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 1.99777L73.5 44L0.749996 86.0022L0.75 1.99777Z" stroke="white" strokeWidth="1.5"/>
      </svg>
      <svg viewBox="0 0 75 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M75 44L-4.07833e-06 87.3013L-2.92811e-07 0.698727L75 44Z" fill="#03F0D4"/>
      </svg>
      <svg viewBox="0 0 75 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 1.99777L73.5 44L0.749996 86.0022L0.75 1.99777Z" stroke="white" strokeWidth="1.5"/>
      </svg>
      <svg viewBox="0 0 75 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M75 44L-4.07833e-06 87.3013L-2.92811e-07 0.698727L75 44Z" fill="#03F0D4"/>
      </svg>
    </div>
  )
}